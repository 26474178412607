
import { Box, Grid, Typography } from '@mui/material';
import slide2 from '../Images/2.jpg';  
const AboutHeader = () => {
  return (
    <>
        <img src={slide2} alt="aboutbackground" className='slide-item-image' style={{  height:"85vh", transition: "ease-in-out 1s"}} />
        <Box className="slide-item-data" sx={{
                 display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap:"2px",
                 padding:{
                    xs: '0em',
                    sm: '1em',
                    md: '2em',
                    lg: '2em',
                    xl: '2em',
                 },
                 top:{
                    xs: '30%',
                    sm: '30%',
                    md: '30%',
                    lg: '30%',
                    xl: '30%',
                 },
                 left:{
                    xs: '2%',
                    sm: '5%',
                    md: '5%',
                    lg: '5%',
                    xl: '5%',
                 },
                 width:{
                    xs: '90%',
                    sm: '80%',
                    md: '70%',
                    lg: '60%',
                    xl: '50%',
                 },
                 }}>
                <Typography component='h3' className='slide-item-project'  color='white'>CAREERS</Typography>
                <Typography component='h1' className='slide-item-heading'  display='flex' fontWeight='600' color='secondary' mb={2}
                sx={{
                    fontSize:{
                        xs: '2.5em',
                        sm: '2.5em',
                        md: '3em',
                        lg: '3em',
                        xl: '3em',
                    }
                }}><Typography  component='h1' className='slide-item-heading'  mr="10px"   fontWeight='600' color='primary' mb={2}
                     sx={{
                    fontSize:{
                        xs: '1em',
                        sm: '1em',
                        md: '1em',
                        lg: '1em',
                        xl: '1em',
                    }}}>OPEN</Typography> JOBS</Typography>
        </Box>
    </>
  )
}

export default AboutHeader