
import {  Button, Typography, Box } from '@mui/material';
import "./CSS/Slideshow.css";
import { Link } from 'react-router-dom';

interface SlideItemProps {
    item: {
        id: string;
        image: string;
        name: string;
        description: string;
    };
}

const SlideItem = ({ item }: SlideItemProps) => {
    return (
        <div >
            <img src={item.image} alt={item.name} className='slide-item-image' />
            <Box className="slide-item-data" sx={{
                 display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap:"2px",
                 padding:{
                    xs: '0em',
                    sm: '1em',
                    md: '2em',
                    lg: '2em',
                    xl: '2em',
                 },
                 top:{
                    xs: '30%',
                    sm: '30%',
                    md: '30%',
                    lg: '30%',
                    xl: '30%',
                 },
                 left:{
                    xs: '2%',
                    sm: '5%',
                    md: '5%',
                    lg: '5%',
                    xl: '5%',
                 },
                 width:{
                    xs: '90%',
                    sm: '80%',
                    md: '70%',
                    lg: '60%',
                    xl: '50%',
                 },
                 }}>
                <Typography component='h3' className='slide-item-project'  color='white'>{item.id}</Typography>
                <Typography component='h1' className='slide-item-heading'  fontWeight='600' color='primary' mb={2}
                sx={{
                    fontSize:{
                        xs: '2.5em',
                        sm: '2.5em',
                        md: '3em',
                        lg: '3em',
                        xl: '3em',
                    }
                }}>{item.name}</Typography>
                <Typography variant='subtitle1' className='slide-item-para' textAlign='start' color='primary' mb={2}>{item.description}</Typography>
                <Link to='/gallery' state={item.name}>
                <Button
                    className="CheckButton"
                    variant='outlined'
                    size='large'
                    style={{
                        color: 'white', // Text color
                        borderColor: 'white', // Border color
                        borderRadius: '20px', // Rounded corners
                        alignSelf: 'flex-start', // Align button to the left
                    }}
                  
                >
                    View Project
                </Button>
                </Link>
            </Box>
        </div>
    );
}

export default SlideItem;
