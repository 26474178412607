import { Box, Grid, ImageList, ImageListItem, Typography, useMediaQuery, useTheme } from "@mui/material";
import "./CSS/ClientWall.css"
import client1 from "./Images/ClientWall/bahria.png"
import client2 from "./Images/ClientWall/omega_logo_3x.png"
import client3 from "./Images/ClientWall/city interiors.png"
import client4 from "./Images/ClientWall/diamond.png"
import client5 from "./Images/ClientWall/farhan_hospital.png"
import client6 from "./Images/ClientWall/fatima memorial.png"
import client7 from "./Images/ClientWall/gop forest.png"
import client8 from "./Images/ClientWall/meditech.png"
import client9 from "./Images/ClientWall/National_Highway_Authority_Logo.png"
import client10 from "./Images/ClientWall/pace.png"
import client11 from "./Images/ClientWall/PVC_Black.png"
import client12 from "./Images/ClientWall/seasons.png"

export const ClientWall = () => {

    const ClientsArray = [
        {
          image: client1,
          id: 'PROJECT P.01',
        },
        {
          image: client2,
          id: 'PROJECT P.02',
        },
        {
          image: client3,
          id: 'PROJECT P.03',
        },
        {
          image: client4,
          id: 'PROJECT P.04',
        },
        {
          image: client5,
          id: 'PROJECT P.05',
        },
        {
          image: client6,
          id: 'PROJECT P.06',
        },
        {
          image: client7,
          id: 'PROJECT P.03',
        },
        {
          image: client8,
          id: 'PROJECT P.03',
        },
        {
          image: client9,
          id: 'PROJECT P.03',
        },
        {
          image: client10,
          id: 'PROJECT P.03',
        },
        {
          image: client11,
          id: 'PROJECT P.03',
        },
        {
          image: client12,
          id: 'PROJECT P.03',
        },
      ];
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Box   sx={{
        padding:{
         xs: "80px 20px 80px 20px",
         sm: "40px 40px 10px 40px",
         md: "120px 60px 120px 60px",
        }
        }}>
            <Grid container>
                <Grid item xs={12} md={4} display='flex' justifyContent='flex-start'>
                        <Typography component='h1' color='primary' className="dot"
                        sx={{
                            fontSize:{
                            xs: '0.8em',
                            sm: '1em',
                            md: '1em',
                            },
                            mb:{
                            xs: '4em',
                            sm: '5em',
                            md: '1em',
                            },
                            
                            }}>CLIENT WALL</Typography>
                </Grid>
                <Grid item xs={12}  md={8} pl={0}>
                    <ImageList
                    variant={isXs ? "masonry" : "standard"}
                    cols={isXs ? 4 : 7}
                    gap={isXs ? 30 : 10}
                    >
                        {ClientsArray.map((item) => (
                    <ImageListItem key={item.image} sx={{
                      width:{
                        xs:"70px",
                        md:"100px",
                      },
                      minHeight:{
                        xs:"70px"},
                      
                    }}>
                        <img src={item.image} alt={item.id} loading="lazy" className={`client-images ${isXs ? 'mobile' : ''}`}
                        
                        />

                        </ImageListItem>))}

                    </ImageList>
                </Grid>
        </Grid>
    </Box>
  )
}
