import React, { useEffect, useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import Navbar from './Navbar';
import Contact from './Contact';
import Footer from './Footer';
import { AllProjects } from './Projects';
import GalleryHeader from './Gallery/GalleryHeader';
import GalleryIntro from './Gallery/GalleryIntro';
import GalleryMainBody from './Gallery/GalleryMainBody';
import "./CSS/AboutPage.css";

const theme = createTheme({
  palette: {
    primary: { main: "#ffffff" },
    secondary: { main: '#C5A47E' },
  },
  typography: { fontFamily: 'Barlow' },
});

const Gallery = (props:any) => {
  const location = useLocation();
  const selectedCategory = location.state;

  const [project, setProject] = useState<any>({});

  useEffect(() => {
    const selectedProject = AllProjects.find((index) => index.name === selectedCategory);
    if (selectedProject) {
      setProject(selectedProject);
    }
  }, [selectedCategory]);

  return (
    <ThemeProvider theme={theme}>
      <Navbar/>
      <GalleryHeader name={project.name} id={project.id} image={project.slide}/>
       <GalleryIntro text1={project.Maintext} text2={project.Subtext}/>
      <GalleryMainBody array={project.subimage || []} /> 
      <Contact/>
      <Footer/>
    </ThemeProvider>
  );
}

export default Gallery;
