import { Box, Button, Grid, Input, Stack, TextField, Typography, MenuItem, FormControl, Select, InputLabel, SelectChangeEvent } from "@mui/material";
import background from "./Images/1.jpg";
import "../CSS/Contact.css";
import emailjs from '@emailjs/browser';
import { useRef, useState, FormEvent } from "react";

export const ContactMainForm = () => {

    const [messageSent, setMessageSent] = useState(false);
    const [service, setService] = useState('');
    const form = useRef<HTMLFormElement>(null);
  
    const handleServiceChange = (event: SelectChangeEvent<string>) => {
        setService(event.target.value);
      };
    


    const sendEmail = (e: FormEvent) => {
        e.preventDefault();
     
        if (form.current === null) return;
    
        emailjs
          .sendForm('service_0yrf2nr', 'template_snb7z0p', form.current, 'KMEBXuMbVzy8gWBFz')
          .then(
            () => {
              console.log('SUCCESS!');
              setMessageSent(true);
              (e.target as HTMLFormElement).reset();
    
              setTimeout(() => {
                setMessageSent(false);
              }, 2000);
            },
            (error: any) => {
              console.log('FAILED...', error.text);
            }
          );
      };

  return (
    <form ref={form} onSubmit={sendEmail} className="Contact-form">
    <Grid container direction="column" spacing={2} pr={10}>
      <Grid item xs={12}>
        <TextField
          label="Name"
          name="from_name"
          variant="standard"
          required
          fullWidth
          sx={{
            '& .MuiInputBase-input': { color: 'white' },
            '& .MuiInputLabel-root': { color: 'white' },
            '& .MuiInputLabel-root.Mui-focused': { color: '#c5a47e' },
            '& .MuiInput-underline:before': { borderBottomColor: 'white' },
            '& .MuiInput-underline:hover:before': { borderBottomColor: '#c5a47e' },
            '& .MuiInput-underline:after': { borderBottomColor: '#c5a47e' }
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Email"
          name="email"
          variant="standard"
          required
          fullWidth
          sx={{
            '& .MuiInputBase-input': { color: 'white' },
            '& .MuiInputLabel-root': { color: 'white' },
            '& .MuiInputLabel-root.Mui-focused': { color: '#c5a47e' },
            '& .MuiInput-underline:before': { borderBottomColor: 'white' },
            '& .MuiInput-underline:hover:before': { borderBottomColor: '#c5a47e' },
            '& .MuiInput-underline:after': { borderBottomColor: '#c5a47e' }
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Phone Number"
          name="phone"
          variant="standard"
          required
          fullWidth
          sx={{
            '& .MuiInputBase-input': { color: 'white' },
            '& .MuiInputLabel-root': { color: 'white' },
            '& .MuiInputLabel-root.Mui-focused': { color: '#c5a47e' },
            '& .MuiInput-underline:before': { borderBottomColor: 'white' },
            '& .MuiInput-underline:hover:before': { borderBottomColor: '#c5a47e' },
            '& .MuiInput-underline:after': { borderBottomColor: '#c5a47e' }
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Message"
          name="message"
          variant="standard"
          required
          fullWidth
          multiline
          rows={4}
          sx={{
            '& .MuiInputBase-input': { color: 'white' },
            '& .MuiInputLabel-root': { color: 'white' },
            '& .MuiInputLabel-root.Mui-focused': { color: '#c5a47e' },
            '& .MuiInput-underline:before': { borderBottomColor: 'white' },
            '& .MuiInput-underline:hover:before': { borderBottomColor: '#c5a47e' },
            '& .MuiInput-underline:after': { borderBottomColor: '#c5a47e' }
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel id="service-select-label" sx={{ color: 'white' }}>Service</InputLabel>
          <Select
            labelId="service-select-label"
            id="service-select"
            name="service"
            value={service}
            label="Service"
            onChange={handleServiceChange}
            required
            sx={{
              '& .MuiSvgIcon-root': { color: 'white' },
              '& .MuiOutlinedInput-notchedOutline': { borderColor: 'white' },
              '& .MuiSelect-select': { color: 'white' },
              '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: 'white' }
            }}
          >
            <MenuItem value="Commercial">Commercial</MenuItem>
            <MenuItem value="Landscape">Landscape</MenuItem>
            <MenuItem value="Urban Design">Urban Design</MenuItem>
            <MenuItem value="Interior Construction">Interior Construction</MenuItem>
            <MenuItem value="Residential">Residential</MenuItem>
            <MenuItem value="Other">Other</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} mt="10px" display='flex' justifyContent='flex-start'>
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          size="large"
          className="CheckButton"
          style={{
            color: 'white', // Text color
            borderColor: 'white', // Border color
            borderRadius: '20px', // Rounded corners
            alignSelf: 'center', // Align button to the right
          }}
        >
          Send
        </Button>
      </Grid>
    </Grid>
  </form>
  )
}
