import { createTheme, ThemeProvider } from '@mui/material/styles';
import "./CSS/AboutPage.css"
import Navbar from './Navbar';
import ContactHeader from './Contact/ContactHeader';
import ContactMainSection from './Contact/ContactMainSection';
import Footer from './Footer';

const theme = createTheme({
    palette: {
      primary: {
        main: "#ffffff",
      },
      secondary: {
        main: '#C5A47E',
      },
    },
    typography:{
      fontFamily:'Barlow'
    }
  });
  
export const ContactPage = () => {
  return (
   
     <ThemeProvider theme={theme}>
        <Navbar/>
       <ContactHeader/>
       <ContactMainSection/>
       <iframe
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d27208.918336173454!2d74.292611!3d31.521007000000004!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39190308f746a21b%3A0x9f15d71b98e8ad04!2s403%2C%20Ravi%20Block%20Allama%20Iqbal%20Town%2C%20Lahore%2C%20Punjab%2054000%2C%20Pakistan!5e0!3m2!1sen!2sus!4v1719599132932!5m2!1sen!2sus" width="100%" height="450" style={{ borderRadius: "10px", marginBottom:"50px"}} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
       <Footer/>
     </ThemeProvider>
   
  );
}
