import { Grid, Typography } from '@mui/material'


const ServicesIntro = (props:any) => {
  return (
   <Grid container display='flex' justifyContent='center'sx={{
    padding:{
     xs: "80px 20px 80px 20px",
     sm: "40px 40px 10px 40px",
     md: "40px 60px 10px 60px",
    }
    }}>
   <Grid item xs={12} sm={10}>
       <Typography component='p' color='#999999' textAlign='start' mt='40px'>{props.text1}</Typography>
       <Typography component='p' color='#999999' textAlign='start' mt='20px'>{props.text2}</Typography>          
   </Grid>
   </Grid>
  )
}

export default ServicesIntro