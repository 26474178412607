import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {createBrowserRouter,RouterProvider} from "react-router-dom";
import {AboutPage} from "./components/AboutPage";
import { ContactPage } from './components/ContactPage';
import { ProjectPage } from './components/ProjectPage';
import {ServicePage} from './components/ServicePage';
import Gallery from './components/Gallery';
import {Careers} from './components/Careers';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/AboutPage",
    element: <AboutPage/>,
  },
  {
    path: "/ContactPage",
    element: <ContactPage/>,
  },
  {
    path: "/ProjectPage",
    element: <ProjectPage/>,
  },
  {
    path: "/ServicePage",
    element: <ServicePage/>,
  },
  {
    path: "/Gallery",
    element: <Gallery/>,
  },
  {
    path: "/Careers",
    element: <Careers/>,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <>
    <RouterProvider router={router} />
  </>,
);


