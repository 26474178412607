import { createTheme, ThemeProvider } from '@mui/material/styles';
import "./CSS/AboutPage.css"
import { Box, Grid, Typography } from '@mui/material';
import slide2 from './Images/2.jpg';  
import Navbar from './Navbar';
import CareersHeader from './Careers/CareersHeader';
import CareersIntro from './Careers/CareersIntro';
import Contact from './Contact';
import Footer from './Footer';


const theme = createTheme({
    palette: {
      primary: {
        main: "#ffffff",
      },
      secondary: {
        main: '#C5A47E',
      },
    },
    typography:{
      fontFamily:'Barlow'
    }
  });
  
export const Careers = () => {
  return (
   
     <ThemeProvider theme={theme}>
        <Navbar/>
       <CareersHeader/>
       <CareersIntro/>
       <Contact/>
       <Footer/>
     </ThemeProvider>
   
  );
}
