import { Box, Grid, IconButton, Stack, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBehance } from '@fortawesome/free-brands-svg-icons';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import "./CSS/About.css";
import "./CSS/Footer.css";

const Footer = () => {
  return (
    <Box padding="40px 50px 40px 50px">
        <Grid container spacing={3}>
            <Grid item xs={12} sm={4} display="flex" justifyContent="flex-start">
                <Typography component="h1" color="primary" className="dot">DISCOVER</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Typography component="h1" color="secondary" display="flex" justifyContent="flex-start" fontSize="1.9em">LAHORE OFFICE</Typography>
                <Typography component="p" color="#999999" textAlign="start" mt="20px">403 Ravi Block Allama Iqbal Town Lahore, Pakistan</Typography>
                <Typography component="p" color="primary" textAlign="start" mt="20px" fontSize='30px' fontWeight='500'>+92 300 840 5705</Typography>
                <Typography component="p" color="primary" textAlign="start" mt="20px" fontSize='30px' fontWeight='500'>+92 320 227 7174</Typography>
                <Typography component="p" color="#999999" textAlign="start" mt="30px" className='footer-email' width='fit-content'>lahore@zjassociates.com</Typography>
                
            </Grid>
            <Grid item xs={12} sm={4}>
                <Typography component="h1" color="secondary" display="flex" justifyContent="flex-start" fontSize="1.9em">SOCIAL MEDIA</Typography>
                <Grid container mt="15px" >
                    <a href="https://www.instagram.com/z.j.associates?igsh=MWcybGRlNmxrcW8xdg==" target="_blank" rel="noopener noreferrer">
                    <IconButton size='large' edge='start' aria-label="Instagram" sx={{ mr: 2, mt: 2, border: '1.5px solid transparent', '&:hover': { borderColor: '#c5a47e' } }}>
                        <InstagramIcon color='primary'/>
                    </IconButton>
                    </a>
                    <a href="https://wa.me/+923008405705" target="_blank" rel="noopener noreferrer">
                        <IconButton size='large' edge='start' aria-label="WhatsApp" sx={{ mr: 2, mt: 2, border: '1.5px solid transparent', '&:hover': { borderColor: '#c5a47e' } }}>
                            <WhatsAppIcon color='primary'/>
                        </IconButton>
                    </a>
                    <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
                        <IconButton size='large' edge='start' aria-label="LinkedIn" sx={{ mr: 2, mt: 2, border: '1.5px solid transparent', '&:hover': { borderColor: '#c5a47e' } }}>
                            <LinkedInIcon color='primary'/>
                        </IconButton>
                    </a>
                    <a href="https://www.behance.net" target="_blank" rel="noopener noreferrer">
                        <IconButton size='large' edge='start' aria-label="Behance" sx={{ mr: 2, mt: 2, border: '1.5px solid transparent', '&:hover': { borderColor: '#c5a47e' } }}>
                            <FontAwesomeIcon icon={faBehance} color='white' fontSize='large' />
                        </IconButton>
                    </a>

                </Grid>
            </Grid>
        </Grid>
        <Stack display='flex' flexDirection='row' justifyContent='space-between' mt={4}>
            <Typography color="#999999">© 2023 ZJ Associates. All right reserved. </Typography>
            <Typography color="#999999">Terms & Conditions</Typography>
        </Stack>
    </Box>
  )
}

export default Footer;
