import { Box, Grid, Stack, Typography } from "@mui/material"
import  Architecture  from "../Images/Logos/architecture-logo.png"
import  Interior  from "../Images/Logos/interior-logo.png"
import  Commercial  from "../Images/Logos/commercial-logo.png"
import  Construction  from "../Images/Logos/construction-logo.png"
import  Urban  from "../Images/Logos/urban-logo.png"

const ServicesMore = () => {
  return (
    <Box   sx={{
        padding:{
         xs: "80px 20px 80px 20px",
         sm: "40px 40px 10px 40px",
         md: "40px 60px 10px 60px",
        }
        }}>
            <Grid container>
                <Grid item xs={12} sm={4} display='flex' justifyContent='flex-start'>
                    <Typography component='h1' color='primary' className="dot"
                    sx={{
                        fontSize:{
                         xs: '0.8em',
                           sm: '0.8em',
                           md: '1em',
                        }
                       }}>MOREOVER</Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                <Typography component='h1' color='primary' display='flex' justifyContent='flex-start' fontSize='1.9em'>OTHER <Typography component='h1' color='secondary' ml='10px' fontSize='1em'>SERVICES</Typography></Typography>
               
                </Grid>
            </Grid>
            <Grid container margin='70px 40px 70px 40px' display='flex' justifyContent='center' >

                <Grid item xs={6} sm={2} margin='30px 0 30px 0'>
                    <img src={Architecture} alt="Architecture" className="About-logo-servicesPage"/>
                    <Typography color='primary' paddingTop='10px'>ARCHITECTURE</Typography>
                </Grid>
                <Grid item xs={6} sm={2} margin='30px 0 30px 0'>
                    <img src={Interior} alt="Architecture" className="About-logo-servicesPage" />
                    <Typography color='primary'paddingTop='10px'>INTERIOR</Typography>
                </Grid>
                <Grid item  xs={6} sm={2} margin='30px 0 30px 0'>
                    <img src={Commercial} alt="Architecture"  className="About-logo-servicesPage"/>
                    <Typography color='primary' paddingTop='10px'>COMMERCIAL</Typography>
                </Grid>
                <Grid item  xs={6} sm={2} margin='30px 0 30px 0'>
                    <img src={Construction} alt="Architecture"  className="About-logo-servicesPage"/>
                    <Typography color='primary' paddingTop='10px'>CONSTRUCTION</Typography>
                </Grid>
                <Grid item  xs={6} sm={2} margin='30px 0 30px 0'>
                    <img src={Urban} alt="Architecture"  className="About-logo-servicesPage"/>
                    <Typography color='primary' paddingTop='10px'>URBAN</Typography>
                </Grid>

            </Grid>
    </Box>
  )
}

export default ServicesMore