import * as React from 'react';
import {
  AppBar, Box, CssBaseline, Divider, Drawer, IconButton, List,
  ListItem, ListItemButton, ListItemText, Toolbar, Typography, Collapse
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';

const drawerWidth = 240;

interface Props {
  window?: () => Window;  // For use in an iframe scenario
}

export default function MenuDrawer(props: Props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [openProjects, setOpenProjects] = React.useState(false);
  const [openServices, setOpenServices] = React.useState(false);
  const [openArchitecture, setOpenArchitecture] =React.useState(false);



  const handleClickArchitecture = () => {
    setOpenArchitecture((prev) => !prev);
  };


  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleClickProjects = () => {
    setOpenProjects(!openProjects);
    setOpenArchitecture(false);
    setOpenServices(false);
  };
  const handleClickServices = () => {
    setOpenServices(!openServices);
    setOpenArchitecture(false);
    setOpenProjects(false);
  };

  const menuItems1 = [
    { name: 'Home', path: '/' },
    { name: 'About', path: '/aboutpage' },
    { name: 'Contact', path: '/contactpage' },
    { name: 'Careers', path: '/careers' }
  ];

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        {menuItems1.map((text, index) => (
              <Link
                to={text.path}
                style={{
                  textDecoration: 'none',
                  color: 'inherit'
                }}
              >
                <ListItem key={text.name} disablePadding>
                  <ListItemButton>
                      <ListItemText primary={text.name} sx={{
                        '&:hover': {
                          color: '#c5a47e'
                        }
                      }} />
                  </ListItemButton>
                </ListItem>
            </Link>
          ))}
        {/*================= Projects drop down========================== */}
        <ListItem disablePadding>
          <ListItemButton onClick={handleClickProjects}>
            <ListItemText primary="Projects" />
            {openProjects ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </ListItem>
        <Collapse in={openProjects} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {[ 'All', 'Completed', 'Ongoing'].map((subItem, index) => (
                <Link
                key={subItem}
                to='/projectpage'
                state={subItem}
                style={{
                  textDecoration: 'none',
                  color: 'inherit'
                }}
                >
              <ListItem key={subItem} sx={{ pl: 4 }}>
                <ListItemButton>
                  <ListItemText primary={subItem} sx={{ '&:hover': { color: '#c5a47e' }}} />
                </ListItemButton>
              </ListItem>
              </Link>
            ))}
          </List>
        </Collapse>
        {/*================= Services drop down========================== */}
        <ListItem disablePadding>
        <ListItemButton onClick={handleClickServices}>
          <ListItemText primary="Services" />
          {openServices ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      </ListItem>
      <Collapse in={openServices} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem sx={{ pl: 4 }}>
            <ListItemButton onClick={handleClickArchitecture}>
              <ListItemText primary="Architecture" />
              {openArchitecture ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          </ListItem>
          <Collapse in={openArchitecture} timeout="auto" unmountOnExit>
            <List component="div" disablePadding sx={{ pl: 4 }}>
              {['Residential', 'Commercial'].map((subItem) => (
                <Link
                  key={subItem}
                  to='/servicepage'
                  state={subItem}
                  style={{
                    textDecoration: 'none',
                    color: 'inherit'
                  }}
                >
                  <ListItem sx={{ pl: 4 }}>
                    <ListItemButton>
                      <ListItemText primary={subItem} sx={{ '&:hover': { color: '#c5a47e' }}} />
                    </ListItemButton>
                  </ListItem>
                </Link>
              ))}
            </List>
          </Collapse>
          {['Interior', 'Construction', 'Urban'].map((subItem) => (
            <Link
              key={subItem}
              to='/servicepage'
              state={subItem}
              style={{
                textDecoration: 'none',
                color: 'inherit'
              }}
            >
              <ListItem sx={{ pl: 4 }}>
                <ListItemButton>
                  <ListItemText primary={subItem} sx={{ '&:hover': { color: '#c5a47e' }}} />
                </ListItemButton>
              </ListItem>
            </Link>
          ))}
        </List>
      </Collapse>
      </List>
      <Divider />
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex', position: 'absolute', right: '-15%', top: '-10%' }}>
      <IconButton size='large' edge='start' aria-label="logo" sx={{ color: 'transparent' }}>
        <MenuIcon color='primary' onClick={handleDrawerToggle} />
      </IconButton>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar />
      </Box>
    </Box>
  );
}
