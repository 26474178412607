import { Grid, ImageList, ImageListItem, useTheme, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import FullscreenCarousel from '../FullscreenCarousel';  // import the new component
import { Blurhash } from 'react-blurhash';

const GalleryMainBody = (props:any) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('md'));

  const [imageLoaded, setimageLoaded] = useState(false);
  const [open, setOpen] = useState(false);
  const [initialIndex, setInitialIndex] = useState(0);

  useEffect(() => {
    
    const img=new Image()
    img.onload = ()=>{
      setimageLoaded(true)
    }
    props.array.map((item:any)=>{
      img.src=item
    })
  }, [props.array])
  
  const handleImageClick = (index:any) => {
    setInitialIndex(index);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Grid item xs={12} pl={0} sx={{
      padding:{
        xs: "80px 20px 80px 20px",
        sm: "40px 40px 10px 40px",
        md: "120px 60px 120px 60px",
      }}}>
      <ImageList
        variant="standard"
        cols={isXs ? 1 : 2}
        gap={isXs ? 30 : 60}
        sx={{ ml:{
          xs:"0px",
          sm:"0px",
          md:"60px",
        }}}
      >
        {props.array.map((item:any, index:any) => (
          <ImageListItem
            key={item}
            cols={isXs ? 1 : ((index + 1) % 3 === 0 ? 2 : 1)}
            rows={1}
            onClick={() => handleImageClick(index)}
          
          >
             <div style={{display: imageLoaded ? 'none' : 'inline'}}>
               <Blurhash
               hash="LBIqW20LTL^l}v9[I@nnHt%#nORj"
               width="100%"
               height="88vh"
               resolutionX={32}
               resolutionY={32}
               punch={1}
               />
             </div>
             <img src={item} alt="galleryitems" loading="lazy" style={{ display : !imageLoaded ? 'none' : 'inline'}} className={`gallery-images ${isXs ? 'mobile' : ''}`}/>
            
          </ImageListItem>
        ))}
      </ImageList>
      <FullscreenCarousel open={open} images={props.array} onClose={handleClose} initialIndex={initialIndex} />
    </Grid>
  );
}

export default GalleryMainBody;

