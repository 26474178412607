import { Box, Grid, Typography } from '@mui/material';
import sideimg1 from "../Images/AboutPage/sideImage2.jpg";
import sideimg2 from "../Images/AboutPage/sideImage1.jpg";

const AboutIntro = () => {
  return (
    <Box   sx={{
        padding:{
         xs: "80px 20px 80px 20px",
         sm: "40px 40px 10px 40px",
         md: "120px 60px 120px 60px",
        }
        }}>
            <Grid container>
                <Grid item xs={12} md={4} display='flex' justifyContent='flex-start'>
                        <Typography component='h1' color='primary' className="dot"
                        sx={{
                            fontSize:{
                            xs: '0.8em',
                            sm: '1em',
                            md: '1em',
                            },
                            mb:{
                            xs: '4em',
                            sm: '5em',
                            md: '1em',
                            },
                            
                            }}>ABOUT</Typography>
                </Grid>
                <Grid item xs={12} md={4} sx={{
                     mb:{
                        xs: '4em',
                        sm: '5em',
                        md: '1em',
                        },
                }}>
                        <Typography component='h1' color='primary' display='flex' justifyContent='flex-start' fontSize='1.9em'>OUR <Typography component='h1' color='secondary' ml='20px' fontSize='1em'> STORY </Typography></Typography>
                        <Typography component='p' color='#999999' textAlign='start' mt='40px'>Founded by Zia Ur Rehman, who graduated with a Master’s in Interior Design from the prestigious National College of Arts, z.j associates has grown into a leading name in Pakistan’s architectural landscape. After gaining invaluable experience working with pioneers of Pakistani architecture such as Nayyer Ali Dada and Gazanfer Ali, Zia Ur Rehman established his solo practice in 1996.From its inception, z.j associates has been dedicated to delivering excellence in design and execution. Our portfolio boasts iconic projects such as the Eiffel Tower in Bahria Town Lahore, the landscaping of Mir Shakeel ur Rehman’s villa, and the Pace Fortress Stadium. These landmarks stand as a testament to our commitment to quality and innovation.</Typography>
                        <Typography component='p' color='#999999' textAlign='start' mt='20px'>Specializing primarily in commercial projects, we have forged strong partnerships with real estate giants like Bahria Town, Park View etc., ensuring that our designs meet the highest standards of excellence. Our commercial projects are known for their aesthetic appeal, functional efficiency, and innovative solutions.In the residential sector, z.j associates has made a significant impact with hundreds of outstanding designs across Lahore, Islamabad, Jhang, Peshawar, and many other cities. Each project reflects our dedication to creating spaces that are not only beautiful but also enhance the quality of life for their inhabitants.At z.j associates, we believe in the power of architecture to transform spaces and communities. Our journey from a solo practice to a major player in the architecture community of Pakistan has been driven by our passion for design and our unwavering commitment to excellence. We look forward to continuing to shape the architectural landscape of Pakistan with innovative and sustainable solutions.</Typography>
                        <Typography component='h1' color='secondary' display='flex' justifyContent='flex-start' mt='20px' fontSize='4em'>28
                            <Typography component='h1' display='flex' flexDirection='column' justifyContent='center' color='primary' ml='10px' mt='10px' fontSize='0.3em'>YEARS
                            <Typography  component='h1'  fontSize='1em'>OF EXPERIENCE</Typography>
                            </Typography>
                        </Typography>
                </Grid>
                <Grid item xs={12} md={4}  display='flex' flexDirection='column' justifyContent='flex-start'>
                    <Grid container display='flex' flexDirection='column' mb="20px">
                        <Grid item display='flex'>
                        <Typography component='h1' color='primary' className="dot vertical-text " sx={{ fontSize:{ xs: '0.8em', sm: '1em',  md: '1em'} , pt:{ xs: '4em', sm: '5em', md: '4em',}, }}>ZIA UR REHMAN</Typography>
                        <Box sx={{
                            width:{
                                xs: '100%',
                                sm: '80%',
                                md: '270px',
                                lg: "350px",
                                xl: "450px",
                            },
                            height:{
                                xs: '100%',
                                sm: '80%',
                                md: '250px',
                                lg: "300px",
                                xl: "350px",
                            }
                        }}> 
                          <img src={sideimg1} alt="side img 1" className='About-side-img' />
                        </Box>
                        </Grid>
                    </Grid>
                    <Grid container display='flex' flexDirection='column'>
                        <Grid item display='flex'>
                        <Typography component='h1' color='primary' className="dot vertical-text " sx={{ fontSize:{ xs: '0.8em', sm: '1em',  md: '1em'} , pt:{ xs: '4em', sm: '5em', md: '4em',}, }}>AYYAN REHMAN</Typography>
                        <Box sx={{
                            width:{
                                xs: '100%',
                                sm: '80%',
                                md: '270px',
                                lg: "350px",
                                xl: "450px",
                            },
                            height:{
                                xs: '100%',
                                sm: '80%',
                                md: '250px',
                                lg: "300px",
                                xl: "350px",
                            }
                        }}> 
                          <img src={sideimg2} alt="side img 1" className='About-side-img' />
                        </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
    </Box>
  )
}

export default AboutIntro