import '../CSS/AboutSlideshow.css';

import sideimg1 from "../Images/AboutPage/sideImage1.jpg";
import sideimg2 from "../Images/AboutPage/sideImage2.jpg";
import sideimg3 from "../Images/AboutPage/sideImage3.jpg";
import AboutSlideCard from './AboutSlideCard';
import { Typography } from '@mui/material';


export const AboutSlideshow = () => {

    return (
    <div className='project-container'  >

        <Typography component='h1' color='primary' display='flex' justifyContent='center' fontSize='1.9em'>OUR <Typography component='h1' color='secondary' ml='20px' fontSize='1em'> TEAM </Typography></Typography>
        <div className="project-container-data">
        <div className='project-container-slide'  >
            <AboutSlideCard image={sideimg1} name="AYYAN REHMAN" text1="ARCHITECT" text2=""/>
            <AboutSlideCard image={sideimg2} name="ZIA UR REHMAN" text1="CEO" text2="" />
            <AboutSlideCard image={sideimg3} name="FATIM NAVEED" text1="IT HEAD" text2="" />
            {/* <AboutSlideCard image={sideimg2} name="MARK LUTHIN" text1="CEO" text2="" /> */}
          
        </div>
        <div className='project-container-slide'  >
            <AboutSlideCard image={sideimg1} name="AYYAN REHMAN" text1="ARCHITECT" text2=""/>
            <AboutSlideCard image={sideimg2} name="ZIA UR REHMAN" text1="CEO" text2="" />
            <AboutSlideCard image={sideimg3} name="FATIM NAVEED" text1="IT HEAD" text2="" />
            {/* <AboutSlideCard image={sideimg2} name="MARK LUTHIN" text1="CEO" text2="" /> */}
        </div>
      
       
        </div>
        
    </div>
  )
}
