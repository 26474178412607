import { createTheme, ThemeProvider } from '@mui/material/styles';
import "./CSS/AboutPage.css"
import Navbar from './Navbar';
import ProjectsHeader from "./Projects/ProjectsHeader"
import ProjectsBody from "./Projects/ProjectsBody"
import Contact from './Contact';
import Footer from './Footer';
import { AllProjects, OngoingProjects, CompletedProjects } from './Projects';
import { useLocation } from 'react-router-dom';


const theme = createTheme({
    palette: {
      primary: {
        main: "#ffffff",
      },
      secondary: {
        main: '#C5A47E',
      },
    },
    typography:{
      fontFamily:'Barlow'
    }
  });
  

  export const ProjectPage = (props:any) => {
    const location = useLocation();
    const selectedCategory=location.state;



    let selectedArray;
    switch (selectedCategory) {
      case "Completed":
        selectedArray = CompletedProjects;
        break;
      case "Ongoing":
        selectedArray = OngoingProjects;
        break;
      default:
        selectedArray = AllProjects;
    }

  return (
   
     <ThemeProvider theme={theme}>
        <Navbar/>
        <ProjectsHeader name={selectedCategory}/>
         <ProjectsBody array={selectedArray}/>
       <Contact/>
       <Footer/>
     </ThemeProvider>
   
  );
}
