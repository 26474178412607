import { Grid, ImageList, ImageListItem, Typography,useTheme, useMediaQuery } from '@mui/material'
import { Link } from 'react-router-dom';

const ServicesBody = (props:any) => {
    
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('md'));
  const handleLinkClick = (route: string) => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  };
  return (
     <Grid item xs={12} pl={0} sx={{
        padding:{
         xs: "80px 20px 80px 20px",
         sm: "40px 40px 10px 40px",
         md: "120px 60px 120px 60px",
        }}}>
    <ImageList
      variant={isXs ? "standard" : "masonry"}
      cols={isXs ? 1 : 2}
      gap={isXs ? 30 : 50}
      
    >
      {props.array.map((item:any) => (
        <ImageListItem key={item.image}>
           <Link to='/gallery' state={item.name}>
          <img src={item.image} alt={item.name} loading="lazy" onClick={() => handleLinkClick('/gallery')} className={`project-images ${isXs ? 'mobile' : ''}`  }
          
          />
          </Link>
          <Typography component="h3" className="project-images-id" color="secondary"
          sx={{
            fontSize:{
              xs: '0.8em',
              sm: '1.2em',
              md: '0.9em',
              lg: '1em',
              xl: '1.2em',
            },
            ml:{
              xs: '0px',
              sm: '40px',
              md: '10px',
              lg: '10px',
              xl: '10px',
            },
          }}>
            {item.id}
          </Typography>
          <Typography component="h1" className="project-images-name"  fontWeight="600" color="primary" mt={2}
           sx={{
            fontSize:{
              xs: '1.2em',
              sm: '1.7em',
              md: '1.3em',
              lg: '1.8em',
              xl: '2em',
            },
            ml:{
              xs: '0px',
              sm: '40px',
              md: '10px',
              lg: '10px',
              xl: '10px',
            },
          }}>
            {item.name}
          </Typography>
        </ImageListItem>
      ))}
    </ImageList>
  </Grid>
  )
}

export default ServicesBody