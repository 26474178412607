import { createTheme, ThemeProvider } from '@mui/material/styles';
import "./CSS/AboutPage.css"
import Navbar from './Navbar';
import Contact from './Contact';
import Footer from './Footer';
import { Architecture, Residential, Interior, Construction, Commercial, Urban } from './Projects';
import { useLocation } from 'react-router-dom';
import ServicesHeader from './Services/ServicesHeader';
import ServicesBody from './Services/ServicesBody';
import ServicesIntro from './Services/ServicesIntro';
import ServicesMore from './Services/ServicesMore';


const theme = createTheme({
  palette: {
    primary: {
      main: "#ffffff",
    },
    secondary: {
      main: '#C5A47E',
    },
  },
  typography: {
    fontFamily: 'Barlow'
  }
});


export const ServicePage = (props: any) => {
  const location = useLocation();
  const selectedCategory = location.state;



  let selectedArray;
  let selectedText;
  let selectedText1;
  switch (selectedCategory) {
    case "Residential":
      selectedArray = Residential;
      selectedText = "Since 1996, we have been veteran architects in the residential building industry, designing homes all across Pakistan."
      selectedText1 = "Our extensive experience and commitment to excellence ensure that each project we undertake meets the highest standards of design, functionality, and innovation. With hundreds of exceptional designs in Lahore, Islamabad, Jhang, Peshawar, and many other cities, we have created beautiful and functional living spaces that enhance the quality of life for our clients."
      break;
    case "Interior":
      selectedArray = Interior;
      selectedText = "Since 1996, under the visionary leadership of Zia Ur Rehman, our firm has been a leader in the interior design industry. Zia, who holds a Master's degree in Interior Design from the National College of Arts, established the practice with a passion for creating exceptional living spaces."
      selectedText1 = "We have designed exquisite interiors for homes across Pakistan, including in major cities like Lahore, Islamabad, Jhang, and Peshawar. Our extensive experience and unwavering commitment to excellence ensure that each project we undertake meets the highest standards of design, functionality, and innovation. At our firm, we create beautiful and functional living spaces that truly reflect our clients' tastes and lifestyles."
      break;
    case "Construction":
      selectedArray = Construction;
      selectedText = "Our construction division has built a reputation for excellence in Pakistan. Our experienced team of professionals is dedicated to delivering high-quality construction services, ensuring that every project meets the highest standards of durability, safety, and aesthetic appeal."
      selectedText1 = "We have successfully constructed homes and commercial buildings in major cities such as Lahore, Islamabad, Jhang, and Peshawar. Our commitment to innovation and meticulous attention to detail guarantee that each project not only fulfills but exceeds our clients' expectations. With a focus on quality craftsmanship and timely delivery, we bring our clients' visions to life, creating structures that stand the test of time."
      break;
    case "Commercial":
      selectedArray = Commercial;
      selectedText = "Since 1996, we have been veteran architects in the commercial building industry, with a proven track record of delivering iconic projects like Sultana Arcade and Pace Lahore. "
      selectedText1 = "Our extensive experience and commitment to excellence ensure that each project we undertake meets the highest standards of design, functionality, and innovation."
      break;
    case "Urban":
      selectedArray = Urban;
      selectedText = "Our expertise in urban design has led to the creation of several housing societies, including the notable Omega Vista in Jhang. We specialize in developing well-planned, sustainable communities that offer a high quality of life for residents."
      selectedText1 = "Our urban design projects focus on creating cohesive, functional, and aesthetically pleasing environments. We integrate green spaces, efficient infrastructure, and modern amenities to ensure our housing societies are not only beautiful but also practical and environmentally friendly. By prioritizing the needs of the community, we create living spaces that foster a sense of belonging and enhance the overall urban experience."
      break;
    default:
      selectedArray = Architecture;
      selectedText = ""
      selectedText1 = "Architecture viverra tristique justo duision vitaen diaminte neque nivamus aestan ateuene artines aringianu the ateliten finibus viverra nec lacus in the nedana mis erodinoe misuscipit non sagie the fermen. Planner inilla duiman at elit finibus viverra nec a acus themo the drudea seneoice misuscipit nonie the fermen miverra the ivite dianne onen nivam"
  }

  return (

    <ThemeProvider theme={theme}>
      <Navbar />
      <ServicesHeader name={selectedCategory} />
      <ServicesIntro text1={selectedText} text2={selectedText1} />
      <ServicesBody array={selectedArray} />
      <ServicesMore />
      <Contact />
      <Footer />
    </ThemeProvider>

  );
}
