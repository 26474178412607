import { Box, Grid, Stack, Typography } from "@mui/material"
import  Architecture  from "../components/Images/Logos/architecture-logo.png"
import  Interior  from "../components/Images/Logos/interior-logo.png"
import  Commercial  from "../components/Images/Logos/commercial-logo.png"
import  Construction  from "../components/Images/Logos/construction-logo.png"
import  Urban  from "../components/Images/Logos/urban-logo.png"
import "./CSS/About.css"

const About = () => {
  return (
    <Box   sx={{
        padding:{
         xs: "80px 20px 80px 20px",
         sm: "40px 40px 10px 40px",
         md: "40px 60px 10px 60px",
        }
        }}>
            <Grid container>
                <Grid item xs={12} sm={4} display='flex' justifyContent='flex-start'>
                    <Typography component='h1' color='primary' className="dot"
                    sx={{
                        fontSize:{
                         xs: '0.8em',
                           sm: '0.8em',
                           md: '1em',
                        }
                       }}>WHO ARE WE?</Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                <Typography component='h1' color='primary' display='flex' justifyContent='flex-start' fontSize='1.9em'>ABOUT <Typography component='h1' color='secondary' ml='10px' fontSize='1em'>US</Typography></Typography>
                <Typography component='p' color='#999999' textAlign='start' mt='40px'>Z.J Associates, a prestigious name in the architecture community of Pakistan since 1996. Founded by the visionary designer Zia Ur Rehman, our firm has been at the forefront of innovative and sustainable design for nearly three decades. With a commitment to excellence and a passion for creating spaces that inspire, we have established ourselves as leaders in the field of architecture and interior design. </Typography>
                <Typography component='p' color='#999999' textAlign='start' mt='20px'>At Z.J Associates, our mission is to transform visions into reality. We specialize in designing functional and aesthetically pleasing spaces that reflect the unique needs and aspirations of our clients. Our approach combines creativity, technical expertise, and meticulous attention to detail to deliver projects that stand the test of time.</Typography>
                <Grid container margin='40px 40px 40px 0'>

                    <Grid item xs={4} sm={4} margin='30px 0 30px 0'>
                        <img src={Architecture} alt="Architecture" className="About-logo"/>
                        <Typography color='primary' padding='10px'>ARCHITECTURE</Typography>
                    </Grid>
                    <Grid item xs={4} sm={4} margin='30px 0 30px 0'>
                        <img src={Interior} alt="Architecture" className="About-logo" />
                        <Typography color='primary'padding='10px'>INTERIOR</Typography>
                    </Grid>
                    <Grid item  xs={4} sm={4} margin='30px 0 30px 0'>
                        <img src={Commercial} alt="Architecture"  className="About-logo"/>
                        <Typography color='primary' padding='10px'>COMMERCIAL</Typography>
                    </Grid>
                    <Grid item  xs={4} sm={4} margin='30px 0 30px 0'>
                        <img src={Construction} alt="Architecture"  className="About-logo"/>
                        <Typography color='primary' padding='10px'>CONSTRUCTION</Typography>
                    </Grid>
                    <Grid item  xs={4} sm={4} margin='30px 0 30px 0'>
                        <img src={Urban} alt="Architecture"  className="About-logo"/>
                        <Typography color='primary' padding='10px'>URBAN</Typography>
                    </Grid>

                </Grid>
                </Grid>
            </Grid>
    </Box>
  )
}

export default About