  // vista conrad
import project1 from './Images/Projects/Vista Conrad/vista conrad (3).webp';  
import project1Slide from './Images/Projects/Vista Conrad/vista conrad (2).webp';  
import project1Gallery1 from './Images/Projects/Vista Conrad/vista conrad (1).webp';  
import project1Gallery2 from './Images/Projects/Vista Conrad/vista conrad.webp';  

// ring road
import project2 from './Images/Projects/Ring Road/003_9 - Photo.webp';  
import project2Slide from './Images/Projects/Ring Road/003_3 - Photo.webp';
import project2Gallery1 from './Images/Projects/Ring Road/002_2 - Photo.webp';
import project2Gallery2 from './Images/Projects/Ring Road/6_2 - Photo (2).webp';
import project2Gallery3 from './Images/Projects/Ring Road/6_2 - Photo (4).webp';
import project2Gallery4 from './Images/Projects/Ring Road/6_2 - Photo (5).webp';
import project2Gallery5 from './Images/Projects/Ring Road/6_2 - Photo (6).webp';
import project2Gallery6 from './Images/Projects/Ring Road/6_2 - Photo (7).webp';
import project2Gallery7 from './Images/Projects/Ring Road/7.webp';
import project2Gallery8 from './Images/Projects/Ring Road/8 copy.webp';
import project2Gallery9 from './Images/Projects/Ring Road/9_2 - Photo (2).webp';
import project2Gallery10 from './Images/Projects/Ring Road/15.webp';
import project2Gallery11 from './Images/Projects/Ring Road/16.webp';
import project2Gallery12 from './Images/Projects/Ring Road/121Scene 6_1 copy.webp';

//residence
import project3 from './Images/Projects/Residence/Residence 134 (5).webp'; 
import project3Slide from "./Images/Projects/Residence/Residence 134 (6).webp";
import project3Gallery1 from './Images/Projects/Residence/Residence 134 (1).webp';
import project3Gallery2 from './Images/Projects/Residence/Residence 134 (2).webp';
import project3Gallery3 from './Images/Projects/Residence/Residence 134 (3).webp';
import project3Gallery4 from './Images/Projects/Residence/Residence 134 (4).webp';

//topical
import project4 from './Images/Projects/Topical/factory.webp';  
import project4Slide from "./Images/Projects/Topical/factory.webp"
import project4Gallery1 from "./Images/Projects/Topical/letopical.webp"
import project4Gallery2 from "./Images/Projects/Topical/factory.webp"

//villas
import project5 from './Images/Projects/Villas/villas (3).webp';  
import project5Slide from './Images/Projects/Villas/villas (1).webp';  
import project5Gallery1 from './Images/Projects/Villas/villas (2).webp';  
import project5Gallery2 from './Images/Projects/Villas/villas (3).webp';  
import project5Gallery3 from './Images/Projects/Villas/villas (5).webp';  
import project5Gallery4 from './Images/Projects/Villas/villas (4).webp';  
import project5Gallery5 from './Images/Projects/Villas/villas (1).webp'; 

//horse fountain
import project6 from './Images/Projects/Horse Fountain/1.webp';  
import project6Slide from './Images/Projects/Horse Fountain/4.webp';  
import project6Gallery1 from './Images/Projects/Horse Fountain/5.webp';  
import project6Gallery2 from './Images/Projects/Horse Fountain/4.webp';  
import project6Gallery3 from './Images/Projects/Horse Fountain/2.webp';  
import project6Gallery4 from './Images/Projects/Horse Fountain/3.webp';  
import project6Gallery5 from './Images/Projects/Horse Fountain/1.webp';  

//eiffel tower
import project7 from './Images/Projects/Eiffel Tower/BT_September_21_09-A.webp'
import project7Slide from './Images/Projects/Eiffel Tower/1_QmiiguWSKG1217rA8XfzsQ.webp'
import project7Gallery1 from './Images/Projects/Eiffel Tower/Bahria-Town-Lahore-1-min.jpg'

//alpine woods
import project8 from './Images/Projects/Alpine Woods/1.webp'
import project8Slide from './Images/Projects/Alpine Woods/2.webp'
import project8Gallery1 from './Images/Projects/Alpine Woods/3.webp'
import project8Gallery2 from './Images/Projects/Alpine Woods/4.webp'

export const AllProjects = [
  
  {
    slide:  project7Slide,
    image: project7Gallery1,
    subimage:[project7Slide,project7,project7Gallery1],
    id: 'PROJECT P.07',
    name: "EIFFEL TOWER",
    Maintext:"We are proud to have proposed the idea and design for the iconic Eiffel Tower in Lahore.",
    Subtext:" This project reflects our ability to blend cultural inspiration with innovative design, creating a unique landmark that stands as a testament to our vision and architectural expertise.",
  },
  {
    slide:  project1Slide,
    image: project1,
    subimage:[project1Gallery1,project1Gallery2],
    id: 'PROJECT P.01',
    name: "VISTA CONRAD",
    Maintext:"Architecture viverra tristique justo duision vitaen diaminte neque nivamus aestan ateuene artines aringianu the ateliten finibus viverra nec lacus in the nedana mis erodino setlie suscipe no curabit tristue. Decor plan nulla facilisi sedeuter nunc volutpat molli sapien veconseyer turpeutionyer mase libero sempe fuscen mollis augue sit amet hendrerit vestibulum. Duisteyerionyer venenatis lacus. Web gravida eros urpis interdum bornare interdum et malesu they adamale fames ache urabitur arcu.",
    Subtext:"Design nila iman the finise viverra nec a lacus themo the seneoice misuscipit non sagie the fermen. Planner inilla duiman at elit finibus viverra nec a acus themo the drudea seneoice misuscipit nonie the fermen miverra the ivite dianne onen nivam.",
  },
  {
    slide:  project2Slide,
    image: project2,
    subimage:[project2Gallery1,project2Gallery2,project2Gallery3,project2Gallery4,project2Gallery5,project2Gallery6,project2Gallery7,project2Gallery8,project2Gallery9,project2Gallery10,project2Gallery11,project2Gallery12],
    id: 'PROJECT P.02',
    name: "RING ROAD REVAMPMENT",
    Maintext:"Architecture viverra tristique justo duision vitaen diaminte neque nivamus aestan ateuene artines aringianu the ateliten finibus viverra nec lacus in the nedana mis erodino setlie suscipe no curabit tristue. Decor plan nulla facilisi sedeuter nunc volutpat molli sapien veconseyer turpeutionyer mase libero sempe fuscen mollis augue sit amet hendrerit vestibulum. Duisteyerionyer venenatis lacus. Web gravida eros urpis interdum bornare interdum et malesu they adamale fames ache urabitur arcu.",
    Subtext:"Design nila iman the finise viverra nec a lacus themo the seneoice misuscipit non sagie the fermen. Planner inilla duiman at elit finibus viverra nec a acus themo the drudea seneoice misuscipit nonie the fermen miverra the ivite dianne onen nivam.",
  },
  {
    slide:  project3Slide,
    image: project3,
    subimage:[project3Gallery1,project3Gallery2,project3Gallery3,project3Gallery4,project3,project3Slide],
    id: 'PROJECT P.03',
    name: "RESIDENCE 641",
    Maintext:"Architecture viverra tristique justo duision vitaen diaminte neque nivamus aestan ateuene artines aringianu the ateliten finibus viverra nec lacus in the nedana mis erodino setlie suscipe no curabit tristue. Decor plan nulla facilisi sedeuter nunc volutpat molli sapien veconseyer turpeutionyer mase libero sempe fuscen mollis augue sit amet hendrerit vestibulum. Duisteyerionyer venenatis lacus. Web gravida eros urpis interdum bornare interdum et malesu they adamale fames ache urabitur arcu.",
    Subtext:"Design nila iman the finise viverra nec a lacus themo the seneoice misuscipit non sagie the fermen. Planner inilla duiman at elit finibus viverra nec a acus themo the drudea seneoice misuscipit nonie the fermen miverra the ivite dianne onen nivam.",
  },
  { 
    slide:  project4Slide,
    image: project4,
    subimage:[project4Gallery1,project4Gallery2],
    id: 'PROJECT P.04',
    name: "LÉ TOPICAL FACTORY",
    Maintext:"Designed with a blend of modernity and utilitarianism, the Topical Printers factory and display area reflects the core ideology of the Topical Group. ",
    Subtext:"The design aims to harmonize contemporary aesthetics with functional efficiency, creating a space that not only showcases their innovative printing solutions but also supports streamlined production processes.",
  },
  {
    slide:  project5Slide,
    image: project5,
    subimage:[project5Gallery1,project5Gallery2,project5Gallery3,project5Gallery4,project5Gallery5],
    id: 'PROJECT P.05',
    name: "FRENCH VILLAS",
    Maintext:"A series of French classical homes for a client, blending timeless elegance with modern functionality. ",
    Subtext:"Each home features intricate architectural details, grand facades, and luxurious interiors, capturing the essence of French classical style while providing a comfortable and sophisticated living experience.",
  },
  {
    slide:  project6Slide,
    image: project6,
    subimage:[project6Gallery1,project6Gallery2,project6Gallery3,project6Gallery4,project6Gallery5],
    id: 'PROJECT P.06',
    name: "HORSE FOUNTAIN",
    Maintext:"The use of raging horses as the centerpiece of fountains in Pakistan started here.",
    Subtext:"Infront of the Bahria facilitation center we designed this public square with this fountain as its center-piece. At the back there is a double-decker bus cafe that visitors just love.",
  },
  {
    slide:  project8Slide,
    image: project8,
    subimage:[project8Gallery1,project8Gallery2,project8Slide,project8],
    id: 'PROJECT P.08',
    name: "ALPINE WOODS",
    Maintext:"Discover our latest architectural marvel in Khanaspur, Pakistan. ",
    Subtext:"This project features a cohesive neighborhood of rustic homes and a hotel building, thoughtfully designed to harmonize with the natural surroundings. Experience modern living with a touch of rustic elegance.",
  },

];

export const OngoingProjects = [
  {
    slide:  project2Slide,
    image: project2,
    subimage:[project2Gallery1,project2Gallery2,project2Gallery3,project2Gallery4,project2Gallery5,project2Gallery6,project2Gallery7,project2Gallery8,project2Gallery9,project2Gallery10,project2Gallery11,project2Gallery12],
    id: 'PROJECT P.02',
    name: "RING ROAD REVAMPMENT",
    Maintext:"Architecture viverra tristique justo duision vitaen diaminte neque nivamus aestan ateuene artines aringianu the ateliten finibus viverra nec lacus in the nedana mis erodino setlie suscipe no curabit tristue. Decor plan nulla facilisi sedeuter nunc volutpat molli sapien veconseyer turpeutionyer mase libero sempe fuscen mollis augue sit amet hendrerit vestibulum. Duisteyerionyer venenatis lacus. Web gravida eros urpis interdum bornare interdum et malesu they adamale fames ache urabitur arcu.",
    Subtext:"Design nila iman the finise viverra nec a lacus themo the seneoice misuscipit non sagie the fermen. Planner inilla duiman at elit finibus viverra nec a acus themo the drudea seneoice misuscipit nonie the fermen miverra the ivite dianne onen nivam.",
  },
  {
    slide:  project3Slide,
    image: project3,
    subimage:[project3Gallery1,project3Gallery2,project3Gallery3,project3Gallery4,project3,project3Slide],
    id: 'PROJECT P.03',
    name: "RESIDENCE 641",
    Maintext:"Architecture viverra tristique justo duision vitaen diaminte neque nivamus aestan ateuene artines aringianu the ateliten finibus viverra nec lacus in the nedana mis erodino setlie suscipe no curabit tristue. Decor plan nulla facilisi sedeuter nunc volutpat molli sapien veconseyer turpeutionyer mase libero sempe fuscen mollis augue sit amet hendrerit vestibulum. Duisteyerionyer venenatis lacus. Web gravida eros urpis interdum bornare interdum et malesu they adamale fames ache urabitur arcu.",
    Subtext:"Design nila iman the finise viverra nec a lacus themo the seneoice misuscipit non sagie the fermen. Planner inilla duiman at elit finibus viverra nec a acus themo the drudea seneoice misuscipit nonie the fermen miverra the ivite dianne onen nivam.",
  },
  {
    slide:  project1Slide,
    image: project1,
    subimage:[project1Gallery1,project1Gallery2],
    id: 'PROJECT P.01',
    name: "VISTA CONRAD",
    Maintext:"Architecture viverra tristique justo duision vitaen diaminte neque nivamus aestan ateuene artines aringianu the ateliten finibus viverra nec lacus in the nedana mis erodino setlie suscipe no curabit tristue. Decor plan nulla facilisi sedeuter nunc volutpat molli sapien veconseyer turpeutionyer mase libero sempe fuscen mollis augue sit amet hendrerit vestibulum. Duisteyerionyer venenatis lacus. Web gravida eros urpis interdum bornare interdum et malesu they adamale fames ache urabitur arcu.",
    Subtext:"Design nila iman the finise viverra nec a lacus themo the seneoice misuscipit non sagie the fermen. Planner inilla duiman at elit finibus viverra nec a acus themo the drudea seneoice misuscipit nonie the fermen miverra the ivite dianne onen nivam.",
  },
  { 
    slide:  project4Slide,
    image: project4,
    subimage:[project4Gallery1,project4Gallery2],
    id: 'PROJECT P.04',
    name: "LÉ TOPICAL FACTORY",
    Maintext:"Architecture viverra tristique justo duision vitaen diaminte neque nivamus aestan ateuene artines aringianu the ateliten finibus viverra nec lacus in the nedana mis erodino setlie suscipe no curabit tristue. Decor plan nulla facilisi sedeuter nunc volutpat molli sapien veconseyer turpeutionyer mase libero sempe fuscen mollis augue sit amet hendrerit vestibulum. Duisteyerionyer venenatis lacus. Web gravida eros urpis interdum bornare interdum et malesu they adamale fames ache urabitur arcu.",
    Subtext:"Design nila iman the finise viverra nec a lacus themo the seneoice misuscipit non sagie the fermen. Planner inilla duiman at elit finibus viverra nec a acus themo the drudea seneoice misuscipit nonie the fermen miverra the ivite dianne onen nivam.",
  },
  {
    slide:  project5Slide,
    image: project5,
    subimage:[project5Gallery1,project5Gallery2,project5Gallery3,project5Gallery4,project5Gallery5],
    id: 'PROJECT P.05',
    name: "FRENCH VILLAS",
    Maintext:"Architecture viverra tristique justo duision vitaen diaminte neque nivamus aestan ateuene artines aringianu the ateliten finibus viverra nec lacus in the nedana mis erodino setlie suscipe no curabit tristue. Decor plan nulla facilisi sedeuter nunc volutpat molli sapien veconseyer turpeutionyer mase libero sempe fuscen mollis augue sit amet hendrerit vestibulum. Duisteyerionyer venenatis lacus. Web gravida eros urpis interdum bornare interdum et malesu they adamale fames ache urabitur arcu.",
    Subtext:"Design nila iman the finise viverra nec a lacus themo the seneoice misuscipit non sagie the fermen. Planner inilla duiman at elit finibus viverra nec a acus themo the drudea seneoice misuscipit nonie the fermen miverra the ivite dianne onen nivam.",
  },
  {
    slide:  project8Slide,
    image: project8,
    subimage:[project8Gallery1,project8Gallery2,project8Slide,project8],
    id: 'PROJECT P.08',
    name: "ALPINE WOODS",
    Maintext:"Discover our latest architectural marvel in Khanaspur, Pakistan. ",
    Subtext:"This project features a cohesive neighborhood of rustic homes and a hotel building, thoughtfully designed to harmonize with the natural surroundings. Experience modern living with a touch of rustic elegance.",
  },
];

export const CompletedProjects = [
  {
    slide:  project7Slide,
    image: project7Gallery1,
    subimage:[project7Slide,project7,project7Gallery1],
    id: 'PROJECT P.07',
    name: "EIFFEL TOWER ",
    Maintext:"Architecture viverra tristique justo duision vitaen diaminte neque nivamus aestan ateuene artines aringianu the ateliten finibus viverra nec lacus in the nedana mis erodino setlie suscipe no curabit tristue. Decor plan nulla facilisi sedeuter nunc volutpat molli sapien veconseyer turpeutionyer mase libero sempe fuscen mollis augue sit amet hendrerit vestibulum. Duisteyerionyer venenatis lacus. Web gravida eros urpis interdum bornare interdum et malesu they adamale fames ache urabitur arcu.",
    Subtext:"Design nila iman the finise viverra nec a lacus themo the seneoice misuscipit non sagie the fermen. Planner inilla duiman at elit finibus viverra nec a acus themo the drudea seneoice misuscipit nonie the fermen miverra the ivite dianne onen nivam.",
  },
  {
    slide:  project6Slide,
    image: project6,
    subimage:[project6Gallery1,project6Gallery2,project6Gallery3,project6Gallery4,project6Gallery5],
    id: 'PROJECT P.06',
    name: "HORSE FOUNTAIN",
    Maintext:"Architecture viverra tristique justo duision vitaen diaminte neque nivamus aestan ateuene artines aringianu the ateliten finibus viverra nec lacus in the nedana mis erodino setlie suscipe no curabit tristue. Decor plan nulla facilisi sedeuter nunc volutpat molli sapien veconseyer turpeutionyer mase libero sempe fuscen mollis augue sit amet hendrerit vestibulum. Duisteyerionyer venenatis lacus. Web gravida eros urpis interdum bornare interdum et malesu they adamale fames ache urabitur arcu.",
    Subtext:"Design nila iman the finise viverra nec a lacus themo the seneoice misuscipit non sagie the fermen. Planner inilla duiman at elit finibus viverra nec a acus themo the drudea seneoice misuscipit nonie the fermen miverra the ivite dianne onen nivam.",
  },
];

export const Architecture = [
  {
    image: project1,
    id: 'PROJECT P.01',
    name: "ULTRA-MODERN HOUSE DESIGN",
  },

];

export const Residential = [
  {
    slide:  project3Slide,
    image: project3,
    subimage:[project3Gallery1,project3Gallery2,project3Gallery3,project3Gallery4,project3,project3Slide],
    id: 'PROJECT P.03',
    name: "RESIDENCE 641",
    Maintext:"Architecture viverra tristique justo duision vitaen diaminte neque nivamus aestan ateuene artines aringianu the ateliten finibus viverra nec lacus in the nedana mis erodino setlie suscipe no curabit tristue. Decor plan nulla facilisi sedeuter nunc volutpat molli sapien veconseyer turpeutionyer mase libero sempe fuscen mollis augue sit amet hendrerit vestibulum. Duisteyerionyer venenatis lacus. Web gravida eros urpis interdum bornare interdum et malesu they adamale fames ache urabitur arcu.",
    Subtext:"Design nila iman the finise viverra nec a lacus themo the seneoice misuscipit non sagie the fermen. Planner inilla duiman at elit finibus viverra nec a acus themo the drudea seneoice misuscipit nonie the fermen miverra the ivite dianne onen nivam.",
  },
  {
    slide:  project5Slide,
    image: project5,
    subimage:[project5Gallery1,project5Gallery2,project5Gallery3,project5Gallery4,project5Gallery5],
    id: 'PROJECT P.05',
    name: "FRENCH VILLAS",
    Maintext:"A series of French classical homes for a client, blending timeless elegance with modern functionality. ",
    Subtext:"Each home features intricate architectural details, grand facades, and luxurious interiors, capturing the essence of French classical style while providing a comfortable and sophisticated living experience.",
  },
];

export const Interior = [
  {
    image: project5,
    id: 'PROJECT P.05',
    name: "ULTRA-LUXURIOUS VILLA",
  },
  
];

export const Construction = [
  {
    image: project1,
    id: 'PROJECT P.01',
    name: "ULTRA-MODERN HOUSE DESIGN",
  },
  {
    image: project2,
    id: 'PROJECT P.02',
    name: "ULTRA-LUXURIOUS VILLA",
  },
];

export const Commercial = [
  {
    slide:  project1Slide,
    image: project1,
    subimage:[project1Gallery1,project1Gallery2],
    id: 'PROJECT P.01',
    name: "VISTA CONRAD",
    Maintext:"Architecture viverra tristique justo duision vitaen diaminte neque nivamus aestan ateuene artines aringianu the ateliten finibus viverra nec lacus in the nedana mis erodino setlie suscipe no curabit tristue. Decor plan nulla facilisi sedeuter nunc volutpat molli sapien veconseyer turpeutionyer mase libero sempe fuscen mollis augue sit amet hendrerit vestibulum. Duisteyerionyer venenatis lacus. Web gravida eros urpis interdum bornare interdum et malesu they adamale fames ache urabitur arcu.",
    Subtext:"Design nila iman the finise viverra nec a lacus themo the seneoice misuscipit non sagie the fermen. Planner inilla duiman at elit finibus viverra nec a acus themo the drudea seneoice misuscipit nonie the fermen miverra the ivite dianne onen nivam.",
  },
  { 
    slide:  project4Slide,
    image: project4,
    subimage:[project4Gallery1,project4Gallery2],
    id: 'PROJECT P.04',
    name: "LÉ TOPICAL FACTORY",
    Maintext:"Designed with a blend of modernity and utilitarianism, the Topical Printers factory and display area reflects the core ideology of the Topical Group. ",
    Subtext:"The design aims to harmonize contemporary aesthetics with functional efficiency, creating a space that not only showcases their innovative printing solutions but also supports streamlined production processes.",
  },
];

export const Urban = [
  {
    slide:  project8Slide,
    image: project8,
    subimage:[project8Gallery1,project8Gallery2,project8Slide,project8],
    id: 'PROJECT P.08',
    name: "ALPINE WOODS",
    Maintext:"Discover our latest architectural marvel in Khanaspur, Pakistan. ",
    Subtext:"This project features a cohesive neighborhood of rustic homes and a hotel building, thoughtfully designed to harmonize with the natural surroundings. Experience modern living with a touch of rustic elegance.",
  },
  {
    slide:  project7Slide,
    image: project7Gallery1,
    subimage:[project7Slide,project7,project7Gallery1],
    id: 'PROJECT P.07',
    name: "EIFFEL TOWER",
    Maintext:"We are proud to have proposed the idea and design for the iconic Eiffel Tower in Lahore.",
    Subtext:" This project reflects our ability to blend cultural inspiration with innovative design, creating a unique landmark that stands as a testament to our vision and architectural expertise.",
  },
  {
    slide:  project6Slide,
    image: project6,
    subimage:[project6Gallery1,project6Gallery2,project6Gallery3,project6Gallery4,project6Gallery5],
    id: 'PROJECT P.06',
    name: "HORSE FOUNTAIN",
    Maintext:"The use of raging horses as the centerpiece of fountains in Pakistan started here.",
    Subtext:"Infront of the Bahria facilitation center we designed this public square with this fountain as its center-piece. At the back there is a double-decker bus cafe that visitors just love.",
  },
  {
    slide:  project2Slide,
    image: project2,
    subimage:[project2Gallery1,project2Gallery2,project2Gallery3,project2Gallery4,project2Gallery5,project2Gallery6,project2Gallery7,project2Gallery8,project2Gallery9,project2Gallery10,project2Gallery11,project2Gallery12],
    id: 'PROJECT P.02',
    name: "RING ROAD REVAMPMENT",
    Maintext:"Architecture viverra tristique justo duision vitaen diaminte neque nivamus aestan ateuene artines aringianu the ateliten finibus viverra nec lacus in the nedana mis erodino setlie suscipe no curabit tristue. Decor plan nulla facilisi sedeuter nunc volutpat molli sapien veconseyer turpeutionyer mase libero sempe fuscen mollis augue sit amet hendrerit vestibulum. Duisteyerionyer venenatis lacus. Web gravida eros urpis interdum bornare interdum et malesu they adamale fames ache urabitur arcu.",
    Subtext:"Design nila iman the finise viverra nec a lacus themo the seneoice misuscipit non sagie the fermen. Planner inilla duiman at elit finibus viverra nec a acus themo the drudea seneoice misuscipit nonie the fermen miverra the ivite dianne onen nivam.",
  },
];