import './App.css';
import Navbar from "./components/Navbar"
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Slideshow from './components/Slideshow';
import About from "./components/About"
import ProjectsFrontpage from "./components/ProjectsFrontpage";
import Footer from "./components/Footer";
import Contact from "./components/Contact";
import { ClientWall } from './components/ClientWall';
const theme = createTheme({
  palette: {
    primary: {
      main: "#ffffff",
    },
    secondary: {
      main: '#C5A47E',
    },
  },
  typography:{
    fontFamily:'Barlow'
  }
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Navbar/>
        <Slideshow/>
        <About/>
        <ProjectsFrontpage/>
        <ClientWall/>
        <Contact/>
        <Footer/>
      </div>
    </ThemeProvider>
  );
}

export default App;
