import { createTheme, ThemeProvider } from '@mui/material/styles';
import "./CSS/AboutPage.css"
import { Box, Grid, Typography } from '@mui/material';
import slide2 from './Images/2.jpg';  
import Navbar from './Navbar';
import AboutHeader from './About/AboutHeader';
import AboutIntro from './About/AboutIntro';
import Contact from './Contact';
import Footer from './Footer';

import  {AboutSlideshow}  from './About/AboutSlideshow';

const theme = createTheme({
    palette: {
      primary: {
        main: "#ffffff",
      },
      secondary: {
        main: '#C5A47E',
      },
    },
    typography:{
      fontFamily:'Barlow'
    }
  });
  
export const AboutPage = () => {
  return (
   
     <ThemeProvider theme={theme}>
        <Navbar/>
       <AboutHeader/>
       <AboutIntro/>
       <AboutSlideshow/>
       <Contact/>
       <Footer/>
     </ThemeProvider>
   
  );
}
