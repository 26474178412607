import React, { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

const AboutSlideCard = (props:any) => {
    useEffect(()=>
        {
            AOS.init();
        },[]);
      
  return (
    <a href='#' className='Project-items'>
    <img src={props.image} alt="person" />
    <h3 className='project-items-heading'   >{props.name}</h3>
    <ul className='project-items-details' > 
        <li>{props.text1}</li> 
        <li>{props.text2}</li> 
    </ul>
    </a>

  )
}

export default AboutSlideCard