import { Box, Grid, Typography } from '@mui/material';

const AboutIntro = () => {
  return (
    <Box   sx={{
        padding:{
         xs: "80px 20px 80px 20px",
         sm: "40px 40px 10px 40px",
         md: "120px 60px 120px 60px",
        }
        }}>
            <Grid container>
                <Grid item xs={12} md={4} display='flex' justifyContent='flex-start'>
                        <Typography component='h1' color='primary' className="dot"
                        sx={{
                            fontSize:{
                            xs: '0.8em',
                            sm: '1em',
                            md: '1em',
                            },
                            mb:{
                            xs: '4em',
                            sm: '5em',
                            md: '1em',
                            },
                            
                            }}>APPLY NOW</Typography>
                </Grid>
                <Grid item xs={12} md={8} sx={{
                     mb:{
                        xs: '4em',
                        sm: '5em',
                        md: '1em',
                        },
                }}>
                        <Typography component='h1' color='primary' display='flex' justifyContent='flex-start' sx={{fontSize:{xs:"1.2em", md:"1.9em"}}}>WE ARE<Typography component='h1' color='secondary' ml='10px' fontSize='1em'> NOT HIRING RIGHT NOW !</Typography></Typography>
                        <Typography component='p' color='#999999' textAlign='start' mt='40px'>Join our team at ZJA. We're always on the lookout for talented professionals and offer exciting internship programs for students and recent graduates.</Typography>
                        <Typography component='p' color='#999999' textAlign='start' mt='20px'>Work with industry experts, gain hands-on experience, and grow your career in a dynamic, innovative, and supportive environment. Explore our current openings and start your journey with us today!</Typography>
                        <Typography component='h1' color='secondary' display='flex' justifyContent='flex-start' mt='20px' fontSize='4em'>28 
                            <Typography component='h1' display='flex' flexDirection='column' justifyContent='center' color='primary' ml='10px' mt='10px' fontSize='0.3em'>YEARS
                            <Typography  component='h1'  fontSize='1em'>OF EXPERIENCE</Typography>
                            </Typography>
                        </Typography>
                </Grid>
            </Grid>
    </Box>
  )
}

export default AboutIntro