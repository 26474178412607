import { Box, Grid, ImageList, ImageListItem, Typography, useMediaQuery, useTheme } from "@mui/material";
import "./CSS/About.css";
import "./CSS/ProjectsFrontpage.css";
import { AllProjects, OngoingProjects, CompletedProjects } from './Projects';
import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { Blurhash } from 'react-blurhash';

interface Project {
  image: string;
  name: string;
  id: string;
}


const ProjectsFrontpage = () => {

  
  const [imageLoaded, setimageLoaded] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("all");

  const handleCategoryChange = (category: string) => {
    setSelectedCategory(category);
  };

  let countProjects: Project[] = [];
  
  switch (selectedCategory) {
    case "completed":
      countProjects = CompletedProjects;
      break;
    case "ongoing":
      countProjects = OngoingProjects;
      break;
    default:
      countProjects = AllProjects;
  }

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('md'));

  const handleLinkClick = (route: string) => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  };

  useEffect(() => {
    
    const img=new Image()
    img.onload = ()=>{
      setimageLoaded(true)
    }
    countProjects.map((item:any)=>{
      img.src=item.image
    })
  }, [countProjects])

  return (
    <Box 
    sx={{
       padding:{
        xs: "10px 20px 40px 20px",
        sm: "10px 40px 40px 40px",
        md: "10px 60px 40px 60px",
       }
       }}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={4} display="flex" justifyContent="flex-start"
         >
          <Typography component="h1" color="primary" className="dot"
           sx={{
            fontSize:{
             xs: '0.8em',
               sm: '0.8em',
               md: '1em',
            }
           }}>DISCOVER</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={8}>
          <Typography component="h1" color="secondary" display="flex" justifyContent="flex-start" fontSize="1.9em"
            sx={{
              fontSize:{
                xs: '2em',
                sm: '2.3em',
                md: '1.9em',
                lg: '1.9em',
                xl: '2.8em',
              },
              padding:{
                xs: '0 0 0 0 ',
                sm: '30px 0 0 0 ',
                md: '0 0 0 0 ',
              },
              justifyContent:{
                xs: 'center',
                sm: 'center',
                md: 'flex-start'
              }
            }}>
            OUR 
            <Typography component="h1" color="primary" ml="10px" fontSize="1em">VISION</Typography>
          </Typography>
          <Typography component="p" color="#999999"  mt="40px"
           sx={{
            marginTop:{
              xs: '1.1em',
              sm: '1.5em',
              md: '1.7em',
              lg: '2em',
              xl: '3em',
            },
            fontSize:{
              xs: '1em',
              sm: '1em',
              md: '1em',
              lg: '1em',
              xl: '1.2em',
            },
            textAlign:{
              xs:"start",
              sm:'start',
              md:'start',
            }
          }}>
            At Z.J Associates, we believe every project tells a story and has a unique narrative. Each inch is meticulously designed in our studios, ensuring unparalleled attention to detail. Our portfolio includes iconic city-level projects such as the Eiffel Tower in Lahore, showcasing our ability to blend creativity with precision. With every design, we aim to create spaces that not only function seamlessly but also resonate deeply with their surroundings and inhabitants.
          </Typography>
          <Grid container margin="40px 40px 40px 0" display="flex" justifyContent="flex-start"
           sx={{
            justifyContent:{
              xs: 'center',
              sm: 'center',
              md: 'flex-start'
            }
          }}>
            <Typography component="h3" color="#999999" ml="0px" className={`project-filter ${selectedCategory === "all" ? 'selected' : ''}`} onClick={() => handleCategoryChange("all")}>All</Typography>
            <Typography component="h3" color="#999999" ml="30px" className={`project-filter ${selectedCategory === "completed" ? 'selected' : ''}`} onClick={() => handleCategoryChange("completed")}>Completed</Typography>
            <Typography component="h3" color="#999999" ml="30px" className={`project-filter ${selectedCategory === "ongoing" ? 'selected' : ''}`} onClick={() => handleCategoryChange("ongoing")}>Ongoing</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} pl={0}>
          <ImageList
            variant={isXs ? "standard" : "masonry"}
            cols={isXs ? 1 : 2}
            gap={isXs ? 30 : 50}
            
          >
            {countProjects.map((item) => (
              
              <ImageListItem key={item.image}>
                <div style={{display: imageLoaded ? 'none' : 'inline'}}>
                      <Blurhash
                      hash="LBBC=0~T0203E2WCogRl4;IW-n-."
                      width="100%"
                      height="88vh"
                      resolutionX={32}
                      resolutionY={32}
                      punch={1}
                      />
                  </div>
                 <Link to='/gallery' state={item.name}>
                <img src={item.image} alt={item.name} loading="lazy" style={{ display : !imageLoaded ? 'none' : 'inline'}} className={`project-images ${isXs ? 'mobile' : ''}`} onClick={() => handleLinkClick('/gallery')}/>
                </Link>
                <Typography component="h3" className="project-images-id" color="secondary"
                sx={{
                  fontSize:{
                    xs: '0.8em',
                    sm: '1.2em',
                    md: '0.9em',
                    lg: '1em',
                    xl: '1.2em',
                  },
                  ml:{
                    xs: '0px',
                    sm: '40px',
                    md: '10px',
                    lg: '10px',
                    xl: '10px',
                  },
                }}>
                  {item.id}
                </Typography>
                <Typography component="h1" className="project-images-name"  fontWeight="600" color="primary" mt={2}
                 sx={{
                  fontSize:{
                    xs: '1.2em',
                    sm: '1.7em',
                    md: '1.3em',
                    lg: '1.8em',
                    xl: '2em',
                  },
                  ml:{
                    xs: '0px',
                    sm: '40px',
                    md: '10px',
                    lg: '10px',
                    xl: '10px',
                  },
                  }}>
                  {item.name}
                </Typography>
              </ImageListItem>
            ))}
          </ImageList>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProjectsFrontpage;
