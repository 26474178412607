import { Dialog, DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Carousel } from 'react-responsive-carousel';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './CSS/FullscreenCarousel.css';

const FullscreenCarousel = (props:any) => {
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      fullScreen
      PaperProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.5)' } }}
    >
      <DialogContent style={{ padding: 0 }}>
        <IconButton
          onClick={props.onClose}
          size='large'
          style={{ position: 'absolute', top: 20, right: 20, color: 'white', zIndex: 1000  }}
        >
          <CloseIcon />
        </IconButton>
        <Carousel
          selectedItem={props.initialIndex}
          showThumbs={true}
          showIndicators={false}
          showStatus={true}
          infiniteLoop
          useKeyboardArrows
          renderArrowPrev={(onClickHandler, hasPrev, label) =>
            hasPrev && (
              <button type="button" onClick={onClickHandler} title={label} className="carousel-arrow carousel-arrow-prev">
                <ArrowBackIosIcon/>
              </button>
            )
          }
          renderArrowNext={(onClickHandler, hasNext, label) =>
            hasNext && (
              <button type="button" onClick={onClickHandler} title={label} className="carousel-arrow carousel-arrow-next">
                <ArrowForwardIosIcon/>
              </button>
            )
          }
        >
          {props.images.map((img:(any), index:(any)) => (
            <div key={index}>
              <img src={img} alt={`carousel-${index}`} style={{ maxHeight: '80vh', objectFit: 'contain' }} />
            </div>
          ))}
        </Carousel>
      </DialogContent>
    </Dialog>
  );
};

export default FullscreenCarousel;
