import { Box, Grid, Typography ,IconButton} from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBehance } from '@fortawesome/free-brands-svg-icons';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { ContactMainForm } from '../Contact/ContactMainForm';

const ContactMainSection = () => {
  return (
    <Box   sx={{
        padding:{
         xs: "40px 20px 80px 20px",
         sm: "40px 40px 10px 40px",
         md: "40px 60px 120px 60px",
        }
        }}>
            <Grid container>
                <Grid item xs={12} md={4} display='flex' justifyContent='flex-start'>
                        <Typography component='h1' color='primary' className="dot"
                        sx={{
                            fontSize:{
                            xs: '0.8em',
                            sm: '1em',
                            md: '1em',
                            },
                            mb:{
                            xs: '4em',
                            sm: '5em',
                            md: '1em',
                            },
                            
                            }}>CONTACT</Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Typography component='h1' color='primary' display='flex' justifyContent='flex-start' fontSize='1.5em' sx={{wordSpacing:"3px"}}>HAVE A PROJECT?<Typography component='h1' color='secondary' ml='20px' fontSize='1em'>LETS TALK</Typography></Typography>
                      <ContactMainForm/>
                   </Grid>
                    <Grid item xs={12} sm={4} mt={10} sx={{ marginTop:{
                        sx:"40px",
                        md:"0px"
                    }}}>
                    <Typography component="h1" color="secondary" display="flex" justifyContent="flex-start" fontSize="1.9em">LAHORE OFFICE</Typography>
                    <Typography component="p" color="#999999" textAlign="start" mt="20px">403 Ravi Block Allama Iqbal Town Lahore, Pakistan </Typography>
                    <Typography component="p" color="primary" textAlign="start" mt="20px" fontSize='30px' fontWeight='500'>+92 300 840 5705</Typography>
                    <Typography component="p" color="primary" textAlign="start" mt="20px" fontSize='30px' fontWeight='500'>+92 320 227 7174</Typography>
                    <Typography component="p" color="#999999" textAlign="start" mt="30px" className='footer-email' width='fit-content'>lahore@zjassociates.com</Typography>
                    <Grid container mt="15px" >
                    <a href="https://www.instagram.com/z.j.associates?igsh=MWcybGRlNmxrcW8xdg==" target="_blank" rel="noopener noreferrer">
                    <IconButton size='large' edge='start' aria-label="Instagram" sx={{ mr: 2, mt: 2, border: '1.5px solid transparent', '&:hover': { borderColor: '#c5a47e' } }}>
                        <InstagramIcon color='primary'/>
                    </IconButton>
                    </a>
                    <a href="https://wa.me/+923008405705" target="_blank" rel="noopener noreferrer">
                        <IconButton size='large' edge='start' aria-label="WhatsApp" sx={{ mr: 2, mt: 2, border: '1.5px solid transparent', '&:hover': { borderColor: '#c5a47e' } }}>
                            <WhatsAppIcon color='primary'/>
                        </IconButton>
                    </a>
                    <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
                        <IconButton size='large' edge='start' aria-label="LinkedIn" sx={{ mr: 2, mt: 2, border: '1.5px solid transparent', '&:hover': { borderColor: '#c5a47e' } }}>
                            <LinkedInIcon color='primary'/>
                        </IconButton>
                    </a>
                    <a href="https://www.behance.net" target="_blank" rel="noopener noreferrer">
                        <IconButton size='large' edge='start' aria-label="Behance" sx={{ mr: 2, mt: 2, border: '1.5px solid transparent', '&:hover': { borderColor: '#c5a47e' } }}>
                            <FontAwesomeIcon icon={faBehance} color='white' fontSize='large' />
                        </IconButton>
                    </a>
                    </Grid>
                    </Grid>
                 
            </Grid>
    </Box>
  )
}

export default ContactMainSection